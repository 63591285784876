import React from "react";
import Card from "react-bootstrap/Card";
import "./MovieCard.css";
import { ImageComponent } from "./ImageComponent";

export const MovieCard = ({ movie }) => {
	let poster = "";

	if (movie?.poster_path) {
		poster = "https://image.tmdb.org/t/p/w500" + movie.poster_path;
	}
	return (
		<Card className="movie-card rounded-0 m-2 border-0">
			<ImageComponent image={poster} type="poster" className={"movie-poster"} />
			{/* <Card.Img
				className="rounded-0"
				src={poster}
				aria-label={
					movie?.title + "(" + movie?.release_date?.split("-")[0] + ")"
				}
			/> */}
			{/* <Card.Title className="d-flex mx-auto justify-content-center align-items-center h-100">
				{movie?.title + "(" + movie?.release_date?.split("-")[0] + ")"}
			</Card.Title> */}
		</Card>
	);
};
