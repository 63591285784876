import React, { useEffect, useState } from "react";
import { MovieCard } from "../components/MovieCard";
import { Container, Image } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getTMDBImage } from "../utilities";
import "./CreateCastPage.css";
import { ImageComponent } from "../components/ImageComponent";
import ActorSearch from "../components/ActorSearch";
import { faBars, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CreateCastPage = () => {
	const [movie, setMovie] = useState();
	const [cast, setCast] = useState();
	const [singleSelections, setSingleSelections] = useState([]);

	let { movieId } = useParams();

	const getMovie = async () => {
		const { data } = await axios.get(`/api/movies/details?movieId=${movieId}`);
		console.log(data);
		setMovie(data.movie);
		setCast(data.cast.cast);
		console.log("i fire once");
	};

	useEffect(() => {
		getMovie();
	}, [movieId]);

	const onDragStart = (e, index) => {
		console.log("drag start ", index);
	};
	const onDragEnter = (e, index) => {
		console.log("drag enter ", index);
	};
	const onDragEnd = (e, index) => {
		console.log("drag end ", index);
	};

	const deleteRow = (id) => {
		setCast(cast.filter((castMem) => id !== castMem.id));
	};

	return (
		<Container className="w-100 d-flex flex-column justify-content-center align-items-center">
			<MovieCard
				className="d-flex justify-content-center"
				key={movie?.id}
				movie={movie}
			></MovieCard>
			{cast?.map((castMember, index) => {
				if (index <= 10) {
					return (
						<div
							className="d-flex w-100 border  m-1 "
							draggable
							onDragStart={(e) => onDragStart(e, castMember.id)}
							onDragEnter={(e) => onDragEnter(e, castMember.id)}
							onDragEnd={(e) => onDragEnd(e, castMember.id)}
							key={castMember.id}
						>
							<div className="w-100 h-100 d-flex justify-content-between align-items-center">
								<span className="d-flex justify-content-start align-items-center h-100">
									<FontAwesomeIcon className="mx-5" icon={faBars} />
									<div className="d-flex align-items-center justify-content-between  h-100">
										<div className="actor-photo-container ">
											<ImageComponent
												className="actor-photo"
												image={castMember?.profile_path}
												type="person"
											></ImageComponent>
										</div>
										<div>{castMember?.name}</div>
									</div>
								</span>
								<div>{castMember?.character}</div>
								<span className="d-flex h-100 justify-content-start align-items-center">
									<div>
										<ActorSearch></ActorSearch>
									</div>
									<div onClick={() => deleteRow(castMember.id)}>
										<FontAwesomeIcon className="mx-5" icon={faTrash} />
									</div>
								</span>
							</div>
						</div>
					);
				}
			})}
		</Container>
	);
};
