import React, { useState, useContext } from "react";
//import { AppContext } from "../../context/AppContext";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const LoginPage = () => {
	const navigate = useNavigate();
	//	const { setCurrentUser } = useContext(AppContext);
	const [data, setData] = useState();

	const handleSubmit = (e) => {
		e.preventDefault();
		axios
			.post("/api/users/login", data)
			.then(({ data }) => {
				sessionStorage.setItem("user", data);
				navigate("/");
				//setCurrentUser(data);
			})
			.catch((err) => {
				console.log(err);
				alert("Something went wrong! Try again.");
			});
	};

	return (
		<div className="d-flex justify-content-center  w-100">
			<Form
				onSubmit={handleSubmit}
				className="mt-3 border-form d-flex justify-content-center flex-column"
				style={{ width: "100%", maxWidth: "400px" }}
			>
				<Form.Group controlId="login-email">
					<Form.Label>Username</Form.Label>
					<Form.Control
						onChange={(e) =>
							setData({ ...data, [e.target.name]: e.target.value })
						}
						type="username"
						name="username"
						placeholder="Enter username"
						required
					/>
				</Form.Group>

				<Form.Group controlId="login-password">
					<Form.Label>Password</Form.Label>
					<Form.Control
						onChange={(e) =>
							setData({ ...data, [e.target.name]: e.target.value })
						}
						type="password"
						name="password"
						placeholder="Password"
						required
					/>
				</Form.Group>

				{/* <Form.Text className="text-right">
					Need an account? Register with us <Link to="/register">here</Link>
				</Form.Text>
				<Form.Text className="text-right">
					Forgot your password? Click <Link to="/resetpassword">here</Link> to
					reset it.
				</Form.Text> */}

				<Button className="mt-3" variant="primary" type="submit">
					Login
				</Button>
			</Form>
		</div>
	);
};
