import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {
	InputGroup,
	Container,
	Form,
	FormControl,
	Button,
} from "react-bootstrap";
import { MovieCard } from "../components/MovieCard";
import { useNavigate } from "react-router-dom";

export const LandingPage = () => {
	const [search, setSearch] = useState("");
	const [movies, setMovies] = useState([]);
	let navigate = useNavigate();

	useEffect(() => {
		getTrendingMovies();
	}, []);

	const getTrendingMovies = async () => {
		try {
			const { data } = await axios.get(`/api/movies/trending`);
			setMovies(data.results);
		} catch (error) {
			console.log(error.message);
		}
	};
	const handleChange = async (e) => {
		setSearch(e.target.value);
		console.log("changing search param");
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { data } = await axios.get(`/api/movies/search?search=${search}`);
		setMovies(data.results);
	};

	return (
		<div>
			<Form onSubmit={handleSubmit} className="w-75 m-3 d-flex mx-auto ">
				<InputGroup>
					<FormControl
						value={search}
						onChange={handleChange}
						className="form-control-lg rounded-0 shadow-none"
					></FormControl>
					<Button type="submit" className="rounded-0 shadow-none">
						Go
					</Button>
				</InputGroup>
			</Form>
			<Container className="d-flex w-75 flex-wrap justify-content-center ">
				{movies?.map((movie) => {
					return (
						<div
							key={movie.id}
							onClick={() => {
								navigate(`/create/${movie.id}`);
							}}
						>
							<MovieCard key={movie.id} movie={movie}></MovieCard>
						</div>
					);
				})}
			</Container>
		</div>
	);
};
