import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUser,
	faFilm,
	faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import "./ImageComponent.css";

export const ImageComponent = ({ image, type, className }) => {
	const [imagePath, setImagePath] = useState();
	useEffect(() => {
		if (image) {
			setImagePath("https://image.tmdb.org/t/p/w500" + image);
		}
	}, [imagePath, image]);

	if (type === "person")
		return (
			<div className="cast-photo-container">
				<FontAwesomeIcon className="overlay" icon={faPenToSquare} />
				{imagePath ? (
					<Image src={imagePath} className={"square-crop " + className}></Image>
				) : (
					<FontAwesomeIcon
						icon={faUser}
						className="square-crop icon-username"
					/>
				)}
			</div>
		);
	if (type === "poster") {
		return (
			<div className="cast-photo-container">
				{imagePath ? (
					<Image src={imagePath} className={"" + className}></Image>
				) : (
					<FontAwesomeIcon icon={faFilm} />
				)}
			</div>
		);
	}
};
