import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { ImageComponent } from "./ImageComponent";
import "./ActorSearch.css";

const ActorSearch = () => {
	const [singleSelections, setSingleSelections] = useState([]);
	const [options, setOptions] = useState([]);

	const changeDropdown = async (args) => {
		const { data } = await axios.get(`/api/actors/search?search=${args}`);
		setOptions(data.results);
	};

	return (
		<div className="actor-search-container">
			<Form>
				<Form.Group>
					<AsyncTypeahead
						id="basic-typeahead-single"
						labelKey="name"
						onSearch={changeDropdown}
						onChange={(stuff) => {
							console.log(stuff);
							setSingleSelections(stuff);
						}}
						clearButton
						options={options}
						placeholder="Choose an Actor"
						selected={singleSelections}
					/>
				</Form.Group>
			</Form>
			<ImageComponent
				className="actor-photo"
				image={singleSelections[0]?.profile_path}
				type="person"
			></ImageComponent>
		</div>
	);
};

export default ActorSearch;
