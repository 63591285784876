import "./App.css";
import { LandingPage } from "./pages/LandingPage";
import { CreateCastPage } from "./pages/CreateCastPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigator } from "./components/Navigator";
import { SecureRoute } from "./components/SecureRoute";
import { LoginPage } from "./pages/LoginPage";

function App() {
	return (
		<div className="App">
			<Navigator></Navigator>
			<BrowserRouter>
				<Routes>
					<Route exact path="/login" element={<LoginPage />} />
					<Route
						exact
						path="/"
						element={
							<SecureRoute>
								<LandingPage />
							</SecureRoute>
						}
					/>
					<Route
						exact
						path="/create/:movieId"
						element={
							<SecureRoute>
								<CreateCastPage />
							</SecureRoute>
						}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
